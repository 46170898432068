$primary-blue: #0d2a8a;
$secondary-blue: lighten($primary-blue, 20);
$primary-white: rgba(255,255,255,0.95);
$light-gray: rgba(0,0,0,0.2);
$read-only-gray: #A7A7A7;
$success-green: #33A532;
$primary-yellow: #F5C211;
$primary-black: rgba(0,0,0,0.90);
$primary-red: #ff0000;
$primary-orange: #FF6347;
$border-radius: 4px;

$sm: 35.5em;
$md: 48em;
$lg: 64em;
$xl: 80em;

$pending-color-light: lighten($primary-blue, 60);
$pending-color-dark: lighten($primary-blue, 50);

$exception-color-light: lighten($primary-yellow, 30);
$exception-color-dark: lighten($primary-yellow, 20);

$rejection-color-light: lighten($primary-red, 35);
$rejection-color-dark: lighten($primary-red, 25);

$approved-color-light: lighten($success-green, 40);
$approved-color-dark: lighten($success-green, 30);

// Main NAPA colours
$napa-primary-blue: $primary-blue;
$napa-secondary-blue: $secondary-blue;
$napa-primary-white: $primary-white;
$napa-primary-yellow: $primary-yellow;
$napa-primary-black: $primary-black;

// Main AAG colours
$aag-primary-blue: #003360;
$aag-secondary-blue: $secondary-blue;
$aag-primary-white: $primary-white;