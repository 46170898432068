@import "styles/vars";
@import 'hamburger';

.header {
   background: $primary-blue;
   height: 80px;

   display: grid;
   grid-template-columns: 44px auto 44px;

   .logoContainer {
       display: inline-block;
       margin: 0 auto;
       padding-top: 9px;
       img {
           height: 42.3px;
       }
   }

   .hamburgerButton {
        outline: none;
        appearance: none;
        border: none;
        background: none;
        color: $primary-white;
        // float: right;
        cursor: pointer;
        padding: 1rem 1.8rem;

        i {
            line-height: 30px;
        }
   }

   .hamburgerButton:hover {
       color: darken($primary-white, 20);
   }

   .hamburgerMenu {
       appearance: none;
       outline: none;
       background: none;
       border: none;
       height: 100%;
   }

   ul {
       margin: 0;
       padding: 0;
       list-style: none;

       li a {
           padding: 2rem;
           color: $primary-white;
           text-decoration: none;
           display: block;
           font-size: 2rem;
       }

       li button.button__myPrograms {
            outline: none;
            appearance: none;
            border: 0;
            padding: 2rem;
            font-size: 2rem;
            color: $primary-white;
            cursor: pointer;
            text-decoration: none;
            width: 100%;
            background: none;
            text-align: left;
       }

       a.active {
           background: darken($primary-blue, 5);
       }
   }

}

.menu {
    display: none;
}

.menu--expanded {
    display: block;
    height: 100vh;
    position: fixed;
    background: $primary-blue;
    top: 60px;
    right: 0;
    z-index: 999;
    width: 100%;
}

.button__logout {
    outline: none;
    appearance: none;
    border: 0;
    padding: 2rem;
    font-size: 2rem;
    color: $primary-white;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    background: none;
}

button.language {
    outline: none;
    appearance: none;
    margin: 0;
    padding: 2rem;
    border: none;
    background: none;
    width: 100%;
    color: $primary-white;
}

@media screen and (max-width: $lg) {
    .menu a {
        text-align: center;
    }
}

@media screen and (min-width: $lg) {
    .menu--expanded {
        max-width: 320px;
    }
}
