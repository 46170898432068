@import "styles/vars";

.wrapper {
    height: 100%;
    background: #fafafa;
    overflow-y: auto;

    .constrain {
        max-width: 96rem;
        margin: 0 auto;
    }

}

.wrapper--agent {
    height: 100%;
    background: #fafafa;

    display: grid;
    grid-template-columns: 80px 1fr;

    .navContainer {
        background: $primary-blue;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        a, button {
            appearance: none;
            outline: none;
            width: 100%;
            border: none;
            background: none;
            margin: 0;
            display: block;
            color: $primary-white;
            text-decoration: none;
            text-align: center;
            font-size: .9rem;
            text-transform: uppercase;
            letter-spacing: 0.12em;
            padding: 2rem 0;
            cursor: pointer;
            i {
                display: block;
                margin-bottom: 1rem;
            }
        }
        
        a:hover, button:hover {
            background: lighten($primary-blue, 10);
        }

    }

}