@import 'vars';
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');

* {
    scrollbar-width: thin;
    scrollbar-color: $primary-blue #eee;
    -webkit-font-smoothing: antialiased;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track {
    background: #eee;
}
*::-webkit-scrollbar-thumb {
    background-color: $primary-blue;
    border-radius: 3px;
    border: 1px solid $primary-blue;
}

html {
  font-size: 62.5%; 
}

root, html, body, #root {
    height: 100%;
}

#root.blur {
    filter: blur(2px);
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-size: 1.6rem;
    background: $primary-blue;
    color: $primary-black;
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
    color: $primary-blue;
    letter-spacing: 0.02em;
    font-weight: normal;
}
  

h1 {
    font-size: 2.2rem;
    margin-top: 0;
}

h2 {
font-size: 1.9rem;
padding: 1rem 0 1rem 0;
margin: 2rem 0 0 0;
}

.companyContainer {
    height: 100vh;
}